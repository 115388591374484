<template>
    <div class="admin-content">
        <h3>게시판 글쓰기</h3>
        <div class="flex-wrap right">
            <div>
                <div class="btn-wrap">
                    <button class="btn-box" @click="methods.getCimsLibraryList">목록</button>
                    <button class="btn-box purple" @click="methods.moveModify">수정</button>
                    <button class="btn-box red" @click="methods.deleteCimsLibraryDetail">삭제</button>
                </div>
            </div>
        </div>
        <!-- 상세 내용 -->
		<div class="table-wrap table-list none-scroll-y mt50">
			<div class="table-box">
				<table>
					<colgroup>
						<col width="15%">
						<col width="85%">
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">제목</th>
							<td v-text='state.dataTable.libraryDetailItems.Title'></td>
						</tr>
						<tr>
							<th scope="row">내용</th>
							<td v-html='state.dataTable.libraryDetailItems.Content'></td>
						</tr>
						<tr>
							<th scope="row">등록일</th>
							<td v-text='state.dataTable.libraryDetailItems.CreateDate'></td>
						</tr>
						<tr>
							<th scope="row">조회수</th>
							<td v-text='state.dataTable.libraryDetailItems.Hit'></td>
						</tr>
						<tr>
							<th>파일첨부</th>
							<td>
                             <!-- [check-this] 파일첨부 IsContent = "N" && IsThumb = "N" .,. -->
                             <FileUploader ref="fileUploader1" :subUrl="store.getters.boardSubDirectoryPath"
                                    :maxFileCount="1" :attachments="state.dataTable.fileItems"
                                    :isBtnShow="false" />
                            </td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- //상세 내용 -->
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { cimsNoticeDetail } from '@/modules/cims/CIM_CM0121D.js';
import { useRoute } from 'vue-router';
import router from '@/router'
import alertManager from '@/utils/alert-confirm-manager';
import FileUploader from '@/components/FileUploader.vue';
export default {
    name: 'CIM_CM0121D',
    components: { FileUploader },
    setup() {
        // 스토어
        const store = useStore();

        // 라우트
        const vrt = useRoute();

        // 파일 업로드
        const fileUploader1 = ref(null);

        // state
        const state = reactive({
            parameter: {
                // 시퀀스
                Seq : vrt.query.Seq,

                // 시스템코드
                SystCode : cimsNoticeDetail.systemCode,

                // 보드 카테고리
                BoardCategory : 'CIMCM001',

                // 그룹번호
                GroupNo : 0,
            },
            dataTable: {
                // 자료실 상세 정보
                libraryDetailItems: new Object(),

                // 파일 정보
                fileItems: new Array(),
            }
        });

        // 메서드
        const methods = {

            // cims 자료실 상세 조회
            getCimsLibraryDetail: () => {
                cimsNoticeDetail.getKRTBoardContent(JSON.stringify(state.parameter)).then(result=> {
                    state.dataTable.libraryDetailItems = result.Table.rows[0];
                    state.dataTable.fileItems = result.Table1.rows;
                });

            },

            // 목록으로 이동
            getCimsLibraryList: () => {

                router.push({path: '/CIM_CM0100R'});
            },

            // 수정 화면으로 이동
            moveModify: () => {
                var q = vrt.query;
                q["Seq"] = vrt.query.Seq;
                q["Post"] = "U";
                router.push({ name: 'CIM_CM0102E', query: q });
            },

            // cims 자료실 상세 삭제
            deleteCimsLibraryDetail: () => {

                alertManager.confirm.remove().then(() => {
                    store.commit("setLoadingStatus", true);

                    cimsNoticeDetail.deleteBoardContent(JSON.stringify(state.dataTable.libraryDetailItems)).then().then((response) => {

                        if (response.success) {

                            alertManager.toast.remove();
                        }
                        
                    }).finally(() => {
                        // 목록 재조회
                        methods.getCimsLibraryList();
                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });

                });
                
            }
        }

        onMounted(() => {

            // cims 자료실 상세 최초조회
            methods.getCimsLibraryDetail();
        });

        return {
            state,
            methods,
            cimsNoticeDetail,
            store,
            fileUploader1,
        }
    }
}
</script>